/* eslint-disable */
$(function() {
	$(".select-list").select2();

	$(".js-popup").on('click', function () {
		var popup = $(this).data("tab");

		$(".popup[data-tab="+popup+"]").addClass("is-open");	
	});

	$(".js-popup-close").on('click', function () {
		$(".popup").removeClass("is-open");		
	});

	$(".js-aside-search").on('click', function () {
		$(".aside__search").toggleClass("is-open");		
	});

	$(".js-aside__search-close").on('click', function () {
		$(".aside__search").toggleClass("is-open");		
	});


	$(".js-developments-block").masonry({
	  itemSelector: '.js-developments',
	  columnWidth: ".js-developments",
	  gutter: 20
	});


	/*$(".datepicker__block").on('click', function () {
		console.log("sdfsdf");
	});

	$(".datepicker__block img").on('click', function () {
		console.log("sdfsdf");
	});*/

	$('.js-menu-toggle').on('click', function () {
		$(".menu-level").find("a").toggleClass("is-block");
	});

	$( ".js-datepicker" ).datepicker({
	  showOn: "button",
      buttonImage: "images/common/school-calendar.png",
      buttonImageOnly: true
	});

	$(".js-cinema-page").slick({
		slidesToShow: 2,
  		slidesToScroll: 1,
  		nextArrow: 	'<button type="button" class="slick-next"><img src="/images/common/next.png" alt="" /></button>',
  		prevArrow: 	'<button type="button" class="slick-prev"><img src="/images/common/prev.png" alt="" /></button>',
		  responsive: [
		    {
		      breakpoint: 768,
		      settings: {
		        slidesToShow: 1
		      }
		    }
		]
	});

	$(".js-slider-about").slick({
		slidesToShow: 1,
  		slidesToScroll: 1,
  		nextArrow: 	'<button type="button" class="slick-next"><img src="/images/common/next.png" alt="" /></button>',
  		prevArrow: 	'<button type="button" class="slick-prev"><img src="/images/common/prev.png" alt="" /></button>'
	});

	$(".js-slider-common").slick({
		slidesToShow: 1,
		centerMode: true,
		variableWidth: true,
  		nextArrow: 	'<button type="button" class="slick-next"><img src="/images/common/next.png" alt="" /></button>',
  		prevArrow: 	'<button type="button" class="slick-prev"><img src="/images/common/prev.png" alt="" /></button>'
	});

	if ($('#map1').length) {
		var init = function init() {
			var myMap = new ymaps.Map("map1", {
				center: [47.217383, 38.898815],
				zoom: 18,
				controls: []
			});

			var myPlacemark = new ymaps.Placemark(myMap.getCenter(), {});
			myMap.geoObjects.add(myPlacemark);
		};

		ymaps.ready(init);
	}

	if ($('#map2').length) {
		var init = function init() {
			var myMap = new ymaps.Map("map2", {
				center: [47.217383, 38.898815],
				zoom: 18,
				controls: []
			});

			var myPlacemark = new ymaps.Placemark(myMap.getCenter(), {});
			myMap.geoObjects.add(myPlacemark);
		};

		ymaps.ready(init);
	}

	var bHeight = $("body").outerHeight(),
		vHeight = $(window).outerHeight(),
		fHeight = $(".footer").outerHeight(),
		hHeight = $(".header").outerHeight();

		/*console.log ("bHeight = ", bHeight);
		console.log ("vHeight = ", vHeight);
		console.log ("fHeight = ", fHeight);
		console.log ("hHeight = ", hHeight);*/


	$(window).scroll(function () {

		if (($(window).scrollTop() > 54) && ($(window).scrollTop() < (bHeight - ($(window).scrollTop() - fHeight)))) {
			$(".js-map-fixed").addClass('is-fixed').removeClass("is-bottom"); 
		} else if ($(window).scrollTop() < 54) {
			$(".js-map-fixed").removeClass('is-fixed').removeClass("is-bottom");
		} else if ($(window).scrollTop() > (bHeight - ($(window).scrollTop() - fHeight))) {
			$(".js-map-fixed").removeClass('is-fixed').addClass("is-bottom"); 	
		}
		/*} else if ($(window).scrollTop() > ($(window).scrollTop() > (bHeight - ($(window).scrollTop() - fHeight)))) {
			$(".js-map-fixed").removeClass('is-fixed').addClass("is-bottom"); 
		} else {
			$(".js-map-fixed").removeClass('is-fixed').removeClass("is-bottom");
		}*/
	});


	/*SCHEME FLOOR*/

	var activeFloor = 0; 
	var nScale = 1;

	$(".js-scale-plus").on('click', function () {
		
		nScale = nScale + 0.1;

		$(".js-floor-container").css("transform" , "scale("+nScale+")");
	});


	$(".js-scale-minus").on('click', function () {

		nScale = nScale - 0.15;
		$(".js-floor-container").css("transform" , "scale("+nScale+")");
	});


	$(".js-floor-svg-item").on('click', function () {
		var nFloor = $(this).data("section"),
			nShop = $(this).data("shop"),
			nWidth = $(".js-floor-svg-item[data-shop="+nShop+"][data-section ="+nFloor+"]")[0].getBoundingClientRect().width,
			offset = $(".js-floor-svg-item[data-shop="+nShop+"][data-section ="+nFloor+"]").offset();

			if (nFloor != activeFloor) {
			$(".js-floor-popup").removeClass("is-active");	
			setTimeout(function () {
				offset = $(".js-floor-svg-item[data-shop="+nShop+"][data-section ="+nFloor+"]").offset();
				$(".js-floor-popup").offset({top:offset.top - 175, left:offset.left - 118 + (nWidth/2)} ).addClass("is-active");
			}, 250); 

			} else {
				$(".js-floor-popup").offset({top:offset.top - 175, left:offset.left - 118 + (nWidth/2)} ).addClass("is-active");
			}

	});		

	/*$(".js-floor-svg-item").on('click', function () {
		var nFloor = $(this).data("section"),
			nShop = $(this).data("shop"),
			nWidth = $(this[data-shop="+nShop+"][data-section ="+nFloor+"])[0].getBoundingClientRect().width,
			offset = $(this[data-shop="+nShop+"][data-section ="+nFloor+"]).offset();


		if (nFloor != activeFloor) {
			$(".js-floor-popup").removeClass("is-active");	
			setTimeout(function () {
				offset = $(".js-floor-svg-item[data-shop="+nShop+"][data-section ="+nFloor+"]").offset();
				$(".js-floor-popup").offset({top:offset.top - 175, left:offset.left - 118 + (nWidth/2)} ).addClass("is-active");
			}, 250); 

		} else {
			$(".js-floor-popup").offset({top:offset.top - 175, left:offset.left - 118 + (nWidth/2)} ).addClass("is-active");
		}
	});*/




	$(".js-shop-name").on('click', function () {
		var nFloor = $(this).data("section"),
			nShop = $(this).data("shop"),
			nWidth = $(".js-floor-svg-item[data-shop="+nShop+"][data-section ="+nFloor+"]")[0].getBoundingClientRect().width,
			offset = $(".js-floor-svg-item[data-shop="+nShop+"][data-section ="+nFloor+"]").offset();

		/*console.log("nShop = " , nShop);	
		console.log("nFloor = " , nFloor);	
		console.log(offset.top );
		console.log(offset.left);
		console.log($(".js-floor-svg-item[data-shop="+nShop+"][data-section ="+nFloor+"]")[0].getBoundingClientRect().width);*/

		if (nFloor != activeFloor) {
			$(".js-floor-popup").removeClass("is-active");	
			setTimeout(function () {
				offset = $(".js-floor-svg-item[data-shop="+nShop+"][data-section ="+nFloor+"]").offset();
				$(".js-floor-popup").offset({top:offset.top - 175, left:offset.left - 118 + (nWidth/2)} ).addClass("is-active");
			}, 300); 

		} else {
			$(".js-floor-popup").offset({top:offset.top - 175, left:offset.left - 118 + (nWidth/2)} ).addClass("is-active");
		}

		activeFloor = nFloor;

		$(".js-floor-svg-item").removeClass("is-active");	
		$(".js-floor-svg-item[data-shop="+nShop+"][data-section ="+nFloor+"]").addClass("is-active");

		$(".js-nav").removeClass("is-active");	
		$(".js-nav[data-level="+nFloor+"]").addClass("is-active");

		$(".js-level").removeClass("is-active");
		$(".js-level[data-level="+nFloor+"]").addClass("is-active").removeClass("is-prev").removeClass("is-next");

	});



	$(".floor-nav__title-mobile").on('click', function () {
		$(".floor-nav__list").slideToggle(function() {

  		});
	});


	$(".js-section").on('click', function () {
		var nType = $(this).data("type");

		$(".js-floor-popup").removeClass("is-active");	
		$(".js-floor-svg-item").removeClass("is-active");	
		$(".js-floor-svg-item[data-type="+nType+"]").addClass("is-active");
	});


	$(".js-nav").on('click', function () {
		var nLevel = $(this).data("level"),
			nActive = $(".js-nav.is-active").data("level");

		activeFloor = nLevel;

		if (nLevel > nActive) {
			$(".js-level[data-level="+nActive+"]").addClass("is-prev").removeClass("is-next");	
		}


		if (nLevel < nActive) {
			$(".js-level[data-level="+nActive+"]").addClass("is-next").removeClass("is-prev");	
		}

		$(".js-nav").removeClass("is-active");
		$(this).addClass("is-active");
		$(".js-level").removeClass("is-active");
		$(".js-level[data-level="+nLevel+"]").addClass("is-active").removeClass("is-prev").removeClass("is-next");

		$(".floor-nav__title-mobile").find("span").text(nLevel);

		$(".js-floor-popup").removeClass("is-active");

	});

	$(".floor-search__fixed").on('click', function () {
		$(this).toggleClass("is-active");
		$(".floor-search").toggleClass("is-active");
	});


	$(".js-search-btn").on('click', function () {
		$(".floor-search__fixed").toggleClass("is-active");
		$(".floor-search").toggleClass("is-active");
	});


	$(".js-floor-wrapper").on('click', function () {
		$(".js-floor-wrapper").removeClass("is-active");
		$(this).addClass("is-active");


	});

	//$('.floor-category').jScrollPane();


	$(".js-search-shop").keyup(function() {
		var vValue =  $(this).val(),
			cItem = 0,
			cLevel = 0

		$($(".js-floor-wrapper")).each(function(index , elem) {

		 	cItem = 0;
		 	$($(elem).find(".floor-category__floor")).each(function(index , elem) {
		 		cLevel = 0;
		 		$($(elem).find(".js-shop-name")).each(function(index , elem) {

			 		if (($(elem).find("span").text().indexOf(vValue)) == -1) {
			 			$(elem).addClass("is-hidden").removeClass("is-active");
			 			$(elem).parents(".floor-category__floor").find(".js-level").text(cLevel);
			 			$(elem).parents(".js-floor-wrapper").find(".floor-category__section-count").text("("+cItem+")");
			 		} else {
			 			$(elem).addClass("is-active").removeClass("is-hidden");
			 			cItem = cItem + 1; cLevel = cLevel + 1;
			 			$(elem).parents(".floor-category__floor").find(".js-level").text(cLevel);
			 			$(elem).parents(".js-floor-wrapper").find(".floor-category__section-count").text("("+cItem+")");

			 		} 
		  		});
		  	});

		  	if (cItem == 0) {
		  		$(elem).addClass("is-hidden");
		  	} else {
		  		$(elem).removeClass("is-hidden");
		  	}

		});
	});


});